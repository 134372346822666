export const m = {
  10001: 'Catering Management System',
  10002: 'Not only professional and comprehensive, it can guide new customers to the store for you throughout the network and increase your income significantly.',
  10003: '©STARLUXE  INFORMATION TECHNOLOGY CO.,LIMITED',
  10004: 'Free to download',
  10005: 'No additional fees will be charged for version updates',
  10006: 'Product advantages',
  10007: 'Labor cost reduction',
  10008: 'The IHK ordering system can intuitively improve restaurant management efficiency and reduce labor costs by 28% in every step of ordering, placing orders, adding orders, changing orders, and paying orders.',
  10009: 'Improved ordering efficiency',
  10010: 'Provides a variety of different ways to order food, suitable for all kinds of stores, with excellent customer experience, high ordering efficiency, and good store benefits',
  10011: 'Increase in customer resources',
  10012: 'Online and offline membership operations and continuous accumulation of customer resources can enable stores to produce and distribute coupons, connect more closely with customers, and make marketing easier.',
  10013: 'Improved service quality',
  10014: 'Support various electronic payment methods, smart booking, online queuing, and free charging for customers dining.',
  10015: 'Increased profitability',
  10016: 'Statistical analysis of merchant back-end data, clearly records every order, analyzes customer taste preferences, and the ranking of dishes allows you to seize business opportunities and become stronger.',
  10017: 'Powerful functions',
  10018: 'Scan code to order',
  10019: 'No need to download, just Scan the QR code',
  10020: 'Dine-in take-out',
  10021: 'Diversion of diners, each wonderful',
  10022: 'Online reservation',
  10023: 'Make an appointment in advance to reduce queuing',
  10024: 'AA rule',
  10025: 'Share a table with stranger',
  10026: 'Separate ticket',
  10027: 'Different departments of the restaurant',
  10028: 'Statistics',
  10029: 'Graphic display of sales data',
  10030: 'Cloud monitoring',
  10031: 'Remote monitoring system',
  10032: 'Custom menu',
  10033: "Special menu, it's up to you",
  10034: 'iHK local information classification platform',
  10035: 'i Catering is backed by the largest and most comprehensive information classification platform in Hong Kong, with users from all walks of life, regardless of men, women, young and old, students, white-collar workers, and tourists.',
  10036: 'Catering management',
  10037: 'Housekeeping',
  10038: 'Sightseeing',
  10039: 'car services',
  10040: 'Second hand market',
  10041: 'power bank',
  10042: 'jobs',
  10043: 'Real Estate Agents',
  10044: 'Ticket Center',
  10045: 'Financial lending',
  10046: 'bringing you real passenger flow',
  10047: 'Food map',
  10048: 'Help you display restaurant information on the electronic map of the iHK platform, and make reservations online, bringing additional customer flow to merchants',
  10049: 'Discount',
  10050: 'Universal on all platforms, providing multi-channel display, as long as you are brave enough to make discounts, I will help you talk to the world',
  10051: 'integral Mall',
  10052: 'Consumption points lottery, shopping mall gift redemption, easy to retain customers',
  10053: 'Value-added services',
  10054: "The shared power bank is included in the store, as long as you successfully consume in the restaurant, the rent can be reduced or exempted, and the user's dining satisfaction can be improved",
  10055: 'Package',
  10056: 'We guarantee that the software will be used for free for life, and we will continue to upgrade the version according to market demand, and will never charge extra',
  10057: 'Mobile printer',
  10058: 'i catering Management System',
  10059: 'Buy now',
  10060: 'Bluetooth printer (back kitchen)',
  10061: 'Bluetooth printer (back kitchen + water bar)',
  10062: 'PAD (with bracket)',
  10063: 'Cash register',
  10064: 'Bluetooth printer',
  10065: 'Mobile printer',
  10066: 'Related hardware',
  10067: 'A number of cooperative OEM manufacturers provide hardware support at low prices and good quality',
  10068: 'Bluetooth printer',
  10069: 'Market price',
  10070: 'screen size',
  10071: 'Memory',
  10072: 'Screen rotation',
  10073: '360 degrees',
  10074: 'Screen inclination',
  10075: '180 degree',
  10076: 'screen size',
  10077: 'USB interface',
  10078: 'size',
  10079: '"',
  10080: 'interface',
  10081: 'Wi-Fi, Bluetooth',
  10082: 'Print width',
  10083: 'MAX48mm',
  10084: 'printing speed',
  10085: 'Unpacking method',
  10086: 'Paper out method',
  10087: 'Automatic paper cutting',
  10088: 'Number of stalls',
  10089: 'Four grids and three gears',
  10090: 'Drawer lock',
  10091: 'Three gear lock',
  10092: 'product material',
  10093: 'High-strength high-quality steel',
  10094: 'Automatic/key',
  10095: 'battery capacity',
  10096: 'Screen size',
  10097: 'Print width',
  10098: 'Whether touch screen',
  10099: 'Yes',
  10100: 'use',
  10101: 'All-in-one cash register machine for ordering food and receipt',
  10102: 'Pre-sales & after-sales service',
  10103: 'Installation Instructions',
  10104: 'Professionals are present to guide the installation and use',
  10105: 'Regular maintenance',
  10106: 'On-site follow-up of usage and immediate answers to questions',
  10107: 'Use training',
  10108: 'Staff application training, copywriting, video teaching',
  10109: 'Management consulting',
  10110: 'Online customer service provides technical support at any time',
  10111: 'Free to download and use',
  10112: 'Service Hotline',
  10113: 'E-mail',
  10114: 'official website',
  10115: '©STARLUXE  INFORMATION TECHNOLOGY CO.,LIMITED',
  10116: 'Copyright ©STARLUXE  INFORMATION TECHNOLOGY CO.,LIMITED',
  10117: 'Make an appointment for installation, contact us',
  10118: 'Please type in your name',
  10119: 'Name',
  10120: 'Tel',
  10121: 'Please enter your phone number',
  10122: 'please choose',
  10123: 'Hong Kong, China',
  10124: 'China',
  10125: 'Taiwan, China',
  10126: 'Macao, China',
  10127: 'Company Name',
  10128: 'Please enter company name',
  10129: 'E-mail',
  10130: 'please input your email',
  10131: 'Remarks',
  10132: 'Briefly describe your needs',
  10133: 'Submitted successfully',
  10134: 'Thank you for your attention, we will  contact you as soon as possible',
  10135: 'Incorrect phone format',
  10136: 'E-mail format is incorrect',
  10137: 'Mini',
  10138: 'Suitable for ultra-small catering establishments (milk tea shops, snack shops and other small shops)',
  10139: 'Normal',
  10140: 'Suitable for small catering establishments (tea restaurants, fast food restaurants, etc., stores with back kitchens)',
  10141: 'Professional',
  10142: 'Suitable for medium-sized catering establishments (western restaurants, sushi restaurants, etc., stores with back kitchen water bars)',
  10143: 'Free combination',
  10144: 'Flexible choice, no matter whether it is an expansion of a store or a new branch, you can freely choose suitable hardware equipment',
  10145: 'submit',
  10146: 'Canary',

  20001: '登录i餐饮',
  20002: '免费使用i餐饮',
  20003: '姓名',
  20004: '请输入您的称呼',
  20005: '联系方式',
  20006: '请输入您的手机号',
  20007: '备注',
  20008: '请填写您的需求',
  20009: '提交',
  20010: '菜单',
  20011: '首页',
  20012: 'i餐饮',
  20013: 'i充电',
  20014: '关于我们',
  20015: '免费使用i餐饮HD',
  20016: '提示',
  20017: '提交成功',
  20018: '感谢您的关注，我们将尽快与您联系',
  20019: '确定',
  20020: '提交失败',
  20021: '硬件中心',
  20022: '联系我们',
};
