export default {
  namespaced: true,
  state: {
    common: {},
    navList: [
      {
        index: 0,
        name: { zh_CN: '首页', zh_HK: '首页', en_US: '首页' },
        ref: 'banner',
        top: '',
      },
      {
        index: 1,
        name: { zh_CN: '关于我们', zh_HK: '关于我们', en_US: '关于我们' },
        ref: 'aboutUs',
        top: '',
      },
      {
        index: 2,
        name: { zh_CN: 'i餐饮', zh_HK: 'i餐饮', en_US: 'i餐饮' },
        ref: 'icatering',
        top: '',
      },
      {
        index: 3,
        name: { zh_CN: 'i充电', zh_HK: 'i充电', en_US: 'i充电' },
        ref: 'icharge',
        top: '',
      },
      {
        index: 4,
        name: { zh_CN: '硬件中心', zh_HK: '硬件中心', en_US: '硬件中心' },
        ref: 'product',
        top: '',
      },
      {
        index: 5,
        name: { zh_CN: '联系我们', zh_HK: '联系我们', en_US: '联系我们' },
        ref: 'contactUs',
        top: '',
      },
    ],
    comtent: {},
  },
  getters: {
    appData(state) {
      return state.common;
    },
    navList(state) {
      return state.navList;
    },
    comtent(state) {
      return state.comtent;
    },
  },
  mutations: {},
  actions: {
    init({ state }, params) {
      state.common = params;
    },
    setComtent({ state }, params) {
      state.comtent = params;
    },
  },
};
