import axios from 'axios';
const CanaryUrl = process.env.VUE_APP_CANARY_URL;
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api base_url
  headers: {
    'content-type': 'application/json',
  },
  // timeout: 9000 // 请求超时时间
});

const install = (Vue, app) => {
  // 请求拦截
  http.interceptors.request.use(
    (config) => {
      if (config.url.includes('admin/app/android/version/') || config.url.includes('admin/app/downloadStatistics')) {
        config.baseURL = process.env.VUE_APP_OTHER_URL; // 正式服务器
      }
      if (process.env.NODE_ENV == 'tests' && config.url.indexOf('/canary/') != -1) {
        config.baseURL = CanaryUrl;
        config.url = config.url.replace('/canary/', '/');
      }
      //配置语言
      if (typeof config.params != 'undefined') {
        config.params.locale = app.$i18n.locale;
        if (!config.params.source) {
          config.params.source = 9
        }
      } else {
        config.params = {
          locale: app.$i18n.locale,
          source: 9,
        };
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  // 响应拦截
  http.interceptors.response.use(
    (response) => {
      if (response.status == 200) {
        return response.data || {};
      }
      return Promise.reject(response);
    },
    (err) => {
      return Promise.reject(err); // 返回接口返回的错误信息
    }
  );
  //挂载全局
  Vue.prototype.$axios = http;
  //基本配置
  app.$api.getInit = (params = {}) => http.get('/api/index/init', { params });
  //配置内容
  app.$api.getContent = (params = {}) => http.get('/api/index/content', { params });
  //套餐列表
  app.$api.getPackageIndex = (params = {}) => http.get('/api/package/index', { params });
  //硬件列表
  app.$api.getHardwareIndex = (params = {}) => http.get('/api/hardware/index', { params });
  //联系我们
  app.$api.saveContactus = (params = {}) => http.post('/api/contactus/save', params);
  //单页内容
  app.$api.getPageDetail = (params = {}) => http.get('/api/page/detail', { params });
  //单页列表
  app.$api.getPageIndex = (params = {}) => http.get('/api/page/index', { params });
  // 推广页配置
  app.$api.getSpreadConfig = (params = {}) => http.get('/api/index/content', { params });
};

export default { install };
