export default {
  namespaced: true,
  state: {
    common: {},
    navList: [
      {
        index: 0,
        name: { zh_CN: '首页', zh_HK: '首頁', en_US: 'Home' },
        ref: 'banner',
        top: '',
      },
      {
        index: 1,
        name: { zh_CN: '产品优势', zh_HK: '產品優勢', en_US: 'Advantages' },
        ref: 'advantage',
        top: '',
      },
      {
        index: 2,
        name: { zh_CN: '产品介绍', zh_HK: '產品介紹', en_US: 'Product' },
        ref: 'function',
        top: '',
      },
      {
        index: 3,
        name: { zh_CN: '分布平台', zh_HK: '分布平台', en_US: 'iHK' },
        ref: 'platform',
        top: '',
      },
      {
        index: 4,
        name: { zh_CN: '系统套餐', zh_HK: '系統套餐', en_US: 'Package' },
        ref: 'setmeal',
        top: '',
      },
      {
        index: 5,
        name: { zh_CN: '相关硬体', zh_HK: '相關硬體', en_US: 'hardware' },
        ref: 'hardware',
        top: '',
      },
      {
        index: 6,
        name: { zh_CN: '售前售后', zh_HK: '售前售後', en_US: 'Services' },
        ref: 'service',
        top: '',
      },
      {
        index: 7,
        name: { zh_CN: '联系我们', zh_HK: '聯繫我們', en_US: 'Contact' },
        ref: 'contant',
        top: '',
      },
    ],
    comtent: {},
  },
  getters: {
    appData(state) {
      return state.common;
    },
    navList(state) {
      return state.navList;
    },
    comtent(state) {
      return state.comtent;
    },
  },
  mutations: {},
  actions: {
    init({ state }, params) {
      state.common = params;
    },
    setComtent({ state }, params) {
      state.comtent = params;
    },
  },
};
