export const m = {
  10001: '餐飲管理系統',
  10002: '專業全面，吸納新客，增加收入',
  10003: '© 深圳市上投城科技有限公司',
  10004: '免費下載使用',
  10005: '版本更新絕不加收額外費用',
  10006: '產品優勢',
  10007: '人力成本降低',
  10008: 'iHK點餐系統，從訂枱、落單，加單，改單，埋單的每一步，都能提高樓面管理效率，降低人力成本。',
  10009: '點餐效率提升',
  10010: '提供多種不同的點餐方式，適用於各類門店，顧客體驗極佳，落單效率高。',
  10011: '客戶資源增加',
  10012: '透過線上線下會員共享持續累積客源，衍生之間的優惠，形成良好的銷售循環。',
  10013: '服務質素提高',
  10014: '接受各種電子支付，智能預訂，線上排隊，顧客用餐免費使用充電寶。',
  10015: '盈利能力提升',
  10016: '商戶後台數據統計，記錄訂單，分析顧客口味偏好。受歡迎菜式排名使商戶捉緊商機，更迎合客戶。',
  10017: '強大功能逐個數',
  10018: '掃碼點餐',
  10019: '無需下載，掃碼即點',
  10020: '堂食外帶',
  10021: '食客分流，各自精彩',
  10022: '線上訂位',
  10023: '提前預約，減少排隊',
  10024: 'AA搭枱',
  10025: '搭枱分單，數目分明',
  10026: '分部門出飛',
  10027: '水吧味部，麵檔廚房',
  10028: '數據統計',
  10029: '營銷數據，直觀易見',
  10030: '雲端監控',
  10031: '無須在店，掌控全局',
  10032: '自定菜單',
  10033: '特色餐牌，由你話事',
  10034: 'iHK本地信息分類平台',
  10035: 'i餐飲背靠全港最大最全的信息分類平台，用戶遍佈各行各業，無論男女老幼，學生白領，觀光遊客盡在其中。',
  10036: '餐飲管理',
  10037: '家政服務',
  10038: '旅遊觀光',
  10039: '汽車服務',
  10040: '二手交易',
  10041: '共享充電寶',
  10042: '招聘兼職',
  10043: '地產中介',
  10044: '票務中心',
  10045: '金融借貸',
  10046: '四大特色功能，為你帶來真實客流',
  10047: '美食地圖',
  10048: '在iHK平台電子地圖上幫你展示餐廳資訊，並可在線預約訂位，為商戶帶來額外客流',
  10049: '優惠折扣',
  10050: '全平台通用，提供多渠道展示，只要你夠膽做優惠，我就幫你話俾全世界知',
  10051: '消費積分',
  10052: '消費積分抽獎，禮品兌換，輕鬆留住顧客',
  10053: '增值服務',
  10054: '共享充電寶配套入店，只要在餐廳成功消費即可減免租借費用，提升用戶就餐滿意度',
  10055: '系統套餐計劃',
  10056: '我們保證軟件終身免費使用，並根據市場需求不斷升級版本，絕不另外收費',
  10057: '手持打印機',
  10058: 'i餐飲管理系統',
  10059: '立即購買',
  10060: '藍牙打印機',
  10061: '藍牙打印機（後廚+水吧）',
  10062: 'PAD（含支架）',
  10063: '收銀機',
  10064: '藍牙打印機',
  10065: '手持打印機',
  10066: '相關硬體',
  10067: '多家合作OEM廠家，提供實惠可靠的硬件支持',
  10068: '藍牙印表機',
  10069: '市場價',
  10070: '屏幕尺寸',
  10071: '容量',
  10072: '屏幕旋轉',
  10073: '360度',
  10074: '屏幕倾角',
  10075: '180度',
  10076: '外設接口',
  10077: 'USB接口',
  10078: '尺寸',
  10079: '英寸',
  10080: '連線功能',
  10081: 'Wi-Fi、藍牙',
  10082: '打印寬度',
  10083: 'MAX48mm',
  10084: '打印速度',
  10085: '開箱方式',
  10086: '出紙方式',
  10087: '自動切紙',
  10088: '檔數',
  10089: '四格三檔',
  10090: '抽屜鎖',
  10091: '三檔鎖',
  10092: '產品材質',
  10093: '高強度優質鋼材',
  10094: '自動/鑰匙',
  10095: '電池容量',
  10096: '屏幕大小',
  10097: '打印寬度',
  10098: '是否觸屏',
  10099: '是',
  10100: '用途',
  10101: '落單出飛收銀一體機',
  10102: '售前及售後服務',
  10103: '安裝指導',
  10104: '專業人士到場指導安裝使用',
  10105: '定期維護',
  10106: '上門跟進使用情況，即時解答疑問',
  10107: '使用培訓',
  10108: '各崗位人員應用培訓，文案、視頻教學',
  10109: '管理咨詢',
  10110: '在線客服隨時提供技術支援',
  10111: '免費下載使用',
  10112: '服務熱線',
  10113: '郵箱',
  10114: '官方網址',
  10115: '深圳市上投城科技有限公司',
  10116: 'Copyright © 2021 深圳市上投城科技有限公司 版權所有',
  10117: '預約安裝，聯繫我們',
  10118: '請輸入姓名',
  10119: '姓名',
  10120: '電話號碼',
  10121: '請输入電話號碼',
  10122: '请选择',
  10123: '中國香港',
  10124: '中國大陸',
  10125: '中國台灣',
  10126: '中國澳門',
  10127: '公司名稱',
  10128: '請輸入公司名稱',
  10129: '郵箱',
  10130: '請輸入郵箱',
  10131: '備註',
  10132: '簡單描述一下你的需求',
  10133: '提交成功',
  10134: '感謝您的關注，我們將盡快與您聯繫',
  10135: '手機格式不正確',
  10136: '郵箱格式不正確',
  10137: 'MINI版',
  10138: '適用於超小型餐飲機構<br />（奶茶店、小食店等面積較細的店鋪）',
  10139: '簡易版',
  10140: '適用於小型餐飲機構<br />（茶餐廳、快餐店等，有後廚的店鋪）',
  10141: '專業版',
  10142: '適用於中型餐飲机构<br />(西餐廳、壽司店等，有後廚水吧的店鋪)',
  10143: '自由組合',
  10144: '靈活選擇，無論係店鋪擴充，還是新開分店都可以自由揀選適配的硬件設備',
  10145: '提交',
  10146: '灰度',

  20001: '登录i餐饮',
  20002: '免费使用i餐饮',
  20003: '姓名',
  20004: '请输入您的称呼',
  20005: '联系方式',
  20006: '请输入您的手机号',
  20007: '备注',
  20008: '请填写您的需求',
  20009: '提交',
  20010: '菜单',
  20011: '首页',
  20012: 'i餐饮',
  20013: 'i充电',
  20014: '关于我们',
  20015: '免费使用i餐饮HD',
  20016: '提示',
  20017: '提交成功',
  20018: '感谢您的关注，我们将尽快与您联系',
  20019: '确定',
  20020: '提交失败',
  20021: '硬件中心',
  20022: '联系我们',
};
