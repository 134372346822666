import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'normalize.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/default.css';
import '@/style/iconfont/iconfont.css'; // 字体图标

import VueI18n from 'vue-i18n';
import store from './store';

Vue.use(VueI18n);
Vue.use(ElementUI);

//定义接口对象
Vue.prototype.$api = {};

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh_CN', // 语言标识
  messages: {
    zh_CN: require('./assets/lang/zh'), // 中文语言包
    en_US: require('./assets/lang/en'), // 英文语言包
    zh_HK: require('./assets/lang/hk'), // 繁体
  },
});

Vue.config.productionTip = false;

// 系统区分
Vue.prototype.getOS = () => {
  let u = navigator.userAgent;
  if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
    return 'windows';
  } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
    return 'macOS';
  } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
    return 'ios';
  } else if (u.match(/android/i)) {
    return 'android';
  } else {
    return 'other';
  }
};

// import VConsole from 'vconsole/dist/vconsole.min.js'
// let vConsole = new VConsole()
// console.info('vConsole', vConsole)

const app = new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
});

// 导入接口
import Api from '@/api/axios';
Vue.use(Api, app);

app.$mount('#app');
