<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'App',
  created() {
    //获取配置
    this.$api.getInit().then((res) => {
      if (res.code == 200) {
        this.init(res.data)
      }
    })
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      init: 'app/init',
    }),
  },
}
</script>
