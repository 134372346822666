import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const platform = navigator.userAgent.match(/(iPhone|iPad|Android|ios)/i);
const agreeMent = () => (platform ? import('@/views/Mobile/agreeMent/AgreeMent.vue') : import('@/views/PC/agreeMent/AgreeMent.vue'));

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: () => (platform ? import('@/views/Mobile/home/Index.vue') : import('@/views/PC/home/Index.vue')),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/Mobile/download/Download.vue'),
  },
  {
    path: '/downloadHd',
    name: 'DownloadHd',
    component: () => import('@/views/Mobile/download/DownloadHd.vue'),
  },
  // 推广
  {
    path: '/spread',
    name: 'spread',
    component: () => import('@/views/Mobile/spread/Spread.vue'),
  },
  // 推广1
  {
    path: '/ad1',
    name: 'ad1',
    component: () => import('@/views/Mobile/spread/Spread.vue'),
  },
  // 推广2
  {
    path: '/ad2',
    name: 'ad2',
    component: () => import('@/views/Mobile/spread/Spread.vue'),
  },

  // 推广3
  {
    path: '/ad3',
    name: 'ad3',
    component: () => import('@/views/Mobile/spread/Spread.vue'),
  },
  // 推广5
  {
    path: '/ad5',
    name: 'ad5',
    component: () => import('@/views/Mobile/spread/Spread.vue'),
  },
  // 推广6
  {
    path: '/ad6',
    name: 'ad6',
    component: () => import('@/views/Mobile/spread/Spread.vue'),
  },
  // i餐饮用户协议
  {
    path: '/agreement',
    name: 'AgreeMent',
    meta: {
      name: 'agreement_of_usage',
    },
    component: agreeMent,
  },
  // i餐饮隐私政策
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    meta: {
      name: 'privacypolicy',
    },
    component: agreeMent,
  },
  // 委托扣款授权书
  {
    path: '/authorization',
    name: 'Authorization',
    meta: {
      name: 'authorization',
    },
    component: agreeMent,
  },
  // 押金协议
  {
    path: '/deposit',
    name: 'Deposit',
    meta: {
      name: 'deposit',
    },
    component: agreeMent,
  },
  // 充电宝用户协议
  {
    path: '/agreementCharge',
    name: 'agreementCharge',
    meta: {
      name: 'agreement_charge',
    },
    component: agreeMent,
  },
  // 充电宝隐私政策
  {
    path: '/privacyCharge',
    name: 'privacyCharge',
    meta: {
      name: 'privacy_charge',
    },
    component: agreeMent,
  },
  {
    path: '/download/download',
    redirect: '/download',
    name: 'DownloadDw',
    component: () => import('@/views/Mobile/download/Download.vue'),
  },
  {
    path: '/downloadHd/download',
    redirect: '/downloadHd',
    name: 'DownloadHdDw',
    component: () => import('@/views/Mobile/download/DownloadHd.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
